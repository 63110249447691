<template>
  <div class="fly-footer">
    <footer>
      <div class="wrapper">
        <p class="copyright">
          Copyright © 2022 fukaikiso. All Rights Reserved.
        </p>
        <span><a href="https://beian.miit.gov.cn">粤ICP备2022040269号</a></span>
        <span
          ><img src="@/assets/img/beian.png" /><a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011302003502"
            >粤公网安备 44011302003502号</a
          ></span
        >
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
footer {
  background-color: #f2f5f8;
}

footer .wrapper {
  padding: 30px;
  font-size: 12px;
  color: #aaa;
  text-align: center;
}

footer .wrapper a {
  font-size: 12px;
  color: #aaa;
}
</style>
