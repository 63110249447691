<template>
  <div class="fly-header">
    <div class="wrapper">
      <div class="logo">
        <img src="@/assets/img//logo.png" alt="五叶幽明的小站" class="logo" />
      </div>
      <nav class="fl">
        <ul class="nav_items">
          <li>
            <router-link to="/">首页</router-link>
          </li>
          <li>
            <router-link to="/wooden-knocker">敲木鱼</router-link>
          </li>
        </ul>
      </nav>

      <!-- TODO：夜间模式 -->
      <!-- <div class="search fr">
        <a href="#"><img src="@/assets/img//search.png" alt="" /></a>
      </div>
      <div class="modev fr">
        <a href="#"><img src="@/assets/img//night.png" alt="" /></a>
      </div> -->
      <!-- <div class="submit fr">
        <a href="#"><i class="btn">投稿</i></a>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.fly-header {
  background-color: #fff;
}
.wrapper {
  width: 1240px;
  margin: 0 auto;
}

.fly-header .wrapper {
  height: 80px;
}

.fly-header .wrapper .logo {
  display: block;
  float: left;
  height: 80px;
  margin-right: 60px;
  color: red;
}

.fly-header .wrapper .nav_items > li {
  float: left;
  position: relative;
  margin: 0 20px;
  line-height: 80px;
}

.fly-header .wrapper .nav_items a {
  margin: 0 auto;
}

.fly-header .wrapper .nav_items a:hover {
  color: #ef1616;
}

.fly-header .wrapper .nav_items > li:hover .sub_menu {
  display: block;
  z-index: 100;
  font-size: 14px;
}

.fly-header .wrapper nav .sub_menu {
  display: none;
  position: absolute;
  left: -10px;
  right: -10px;
  line-height: 40px;
  background-color: #fff;
  box-shadow: 0 0 3px;
  text-align: center;
}

.fly-header .wrapper nav ~ div a {
  height: 80px;
  line-height: 80px;
  margin-left: 30px;
  vertical-align: middle;
}

.fly-header .wrapper nav ~ div img {
  width: 20px;
  vertical-align: middle;
}

.fly-header .wrapper .submit .btn {
  box-sizing: border-box;
  padding: 8px 20px;
  margin-right: 20px;
  background-color: #e34f4f;
  border-radius: 3px;
  vertical-align: middle;

  color: #fff;
}

.fly-header .wrapper .submit .btn:hover {
  background-color: #ff3636;
}
</style>
