<template>
  <div>
    <!-- 导航栏 -->
    <fly-header></fly-header>

    <!-- banenr -->
    <div class="banner">
      <div class="stage">
        <div class="space"></div>
        <div class="mountain">
          <div class="mountain1"></div>
          <div class="mountain2"></div>
          <div class="mountain3"></div>
        </div>
      </div>

      <div class="wrapper">
        <!-- <div class="title">fukaikiso的个人小站</div> -->
        <!-- <div class="search">
          <input type="text" placeholder="请输入关键词" />
          <button>搜索</button>
        </div> -->
        <div class="bear"></div>
      </div>
    </div>

    <!-- 主体内容 -->
    <div class="main">
      <div class="wrapper">
        <p>
          当我们谈论心情时，我们进入了一个充满情感和复杂性的领域。心情不仅仅是情绪的表现，它还反映了我们内心深处的状态和周围环境的影响。在日常生活中，我们经历着各种各样的心情，有时候是欢乐与兴奋，有时则是忧郁与沮丧。每一种心情都像是一种心灵的语言，通过它我们与世界交流，与自己对话。
        </p>
        <p>
          有时候，心情如同一朵绽放的花朵，洋溢着无限的喜悦和幸福。这时，我们感受到生活的美好，一切都充满了希望和活力。或许是因为一件小事，一次意外的相遇，或是内心的一种满足感，让我们的心情如此明媚。这种心情让我们轻盈地走在路上，笑容挂在脸上，与周围的人分享着这份喜悦。
        </p>
        <p>
          然而，生活并非总是如此如意，有时我们会陷入情绪低谷，感受到内心的忧伤和无力。这时的心情仿佛被一层阴影笼罩，任何事物都变得沉重而模糊。或许是因为挫折与失望，或是与他人的误解和冲突，使得我们难以摆脱心情的困扰。这种时候，我们需要时间去沉淀，去寻找内心的平静和解脱。
        </p>
        <p>
          有时，心情是复杂而纷繁的，像是交织在一起的色彩和声音。我们可能会感受到喜怒哀乐并存的情绪，这种时候内心仿佛在不同的频道之间切换。或许是因为生活中的多重角色和责任，让我们无法简单地用一个词语来描述自己的心境。这种心情提醒我们，我们是多面向的个体，需要包容和理解自己的每一个情绪。
        </p>
        <p>
          不论是喜悦还是忧伤，每一种心情都是我们内心深处的一部分，它们构成了我们独特的生命体验。学会理解和接纳自己的心情，是与自己建立更深层次连接的一部分。通过与心情的对话，我们可以更好地认识自己，更真实地面对生活的起伏和挑战。
        </p>
        <p>
          因此，让我们珍惜每一种心情，从中汲取力量和智慧。在喜悦中感受生命的美好，在忧伤中学会成长和坚强。无论是阳光明媚的日子，还是风雨飘摇的时刻，我们的心情都是我们内心的一面镜子，反映出我们真实而丰富的生活。让我们怀着感恩的心，与心情对话，与生活共舞。
        </p>
        <p>
          在生活的旅途中，我们经常需要鼓励和支持，来克服困难、追求梦想，以及面对生活的挑战。鼓励的话语如同一束明亮的阳光，能够照亮我们前行的道路，激励我们勇敢前行，不畏艰难。它们不仅仅是言语，更是一种力量，一种希望的源泉，让我们坚定信念，迈向更美好的明天。
        </p>
        <p>
          在人生的旅途中，每个人都会面临各种各样的挑战和困难。有时候，我们可能会感到迷茫和无助，甚至动摇自己的信念和决心。这时候，一句简单而真诚的鼓励就能像是一阵清风，吹散我们心中的阴霾，让我们重新振作起来。它们提醒我们，无论遇到什么困难，我们都有能力和勇气去克服，因为每一次的挑战都是成长和进步的机会。
        </p>
        <p>
          鼓励的话语还能够激发我们内心深处的潜力和激情。它们告诉我们，即使在最艰难的时刻，也要保持信念，相信自己的能力和价值。它们让我们意识到，每一个人都有无限的可能性，只要我们敢于梦想，勇于追求，就能够创造出属于自己的辉煌与成就。
        </p>
        <p>
          此外，鼓励的话语也是人与人之间最温暖和亲密的连接。它们不仅可以给予他人力量和支持，更能够建立起一种深厚的情感纽带。在困难时刻，一个真诚的鼓励能够成为他人前行的动力，让他们感受到社会的温暖和关爱。因此，我们每个人都应该学会用心去倾听和鼓励，因为一句简单的话语可能会改变一个人的命运和人生的方向。
        </p>
        <p>
          最后，鼓励的话语教会我们如何面对生活中的失败和挫折。它们告诉我们，失败并不可怕，重要的是我们如何从中吸取教训，坚持不懈地追求自己的梦想。它们让我们明白，每一次的挫折都是通向成功的必经之路，只要我们不放弃，就一定能够走向光明的未来。
        </p>
        <p>
          因此，让我们在生活中充满鼓励和希望的话语。无论是对自己还是对他人，都要用心去鼓励，用行动去支持。因为鼓励不仅能够改变我们的生活，更能够改变整个世界的面貌。让我们怀揣着信念和勇气，共同前行，创造出更加美好和充满希望的明天。
        </p>
      </div>
    </div>
    <!-- 页尾 -->
    <fly-footer></fly-footer>
  </div>
</template>

<script>
import FlyHeader from '@/components/FlyHeader.vue';
import FlyFooter from '@/components/FlyFooter.vue';
// import indexData from '../../public/json/index.json'
export default {
  components: { FlyHeader, FlyFooter },
  data() {
    return {
      tutorials: [],
      docs: [],
    };
  },
  mounted() {
    this.axios.get('/json/index.json').then((res) => {
      // console.log('res :>> ', res)
      this.tutorials = res.data.tutorials;
      this.docs = res.data.docs;
    });
  },
};
</script>

<style scoped>
.banner {
  position: relative;
  height: 320px;
  width: 100%;
  overflow: hidden;
}

.banner .stage {
  position: absolute;
  height: 320px;
  width: 200%;
  background-color: #000;
}

.banner .stage div {
  height: 320px;
  width: 100%;
}

.banner .stage .space {
  background-image: url(@/assets/img/bg-space.png);
  background-size: auto 50%;
}

.banner .stage .mountain {
  position: absolute;
  top: 60px;
  height: 260px;
}

.banner .stage .mountain div {
  position: absolute;
  height: 260px;
  left: 0;
  bottom: 0;
  background-size: auto 50%;
  background-position: 0 bottom;
}

.banner .stage .mountain1 {
  z-index: 3;
  background: url(@/assets/img/bg-mountain-1.png) repeat-x;
  animation: moving 100s linear 0.8s infinite normal none;
}

.banner .stage .mountain3 {
  z-index: 2;
  background: url(@/assets/img/bg-mountain-3.png) repeat-x;
  animation: moving 150s linear 0.8s infinite normal none;
}

.banner .stage .mountain2 {
  z-index: 1;
  background: url(@/assets/img/bg-mountain-2.png) repeat-x;
  animation: moving 200s linear 0.8s infinite normal none;
}

@keyframes moving {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.banner .wrapper .title {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  min-width: 300px;
  text-align: center;
}

.banner .wrapper .search {
  position: absolute;
  z-index: 4;
  top: 120px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}

.banner .wrapper .search input {
  float: left;
  width: 400px;
  height: 30px;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 5px 0 0 5px;
}

.banner .wrapper .search button {
  float: left;
  width: 50px;
  height: 30px;
  background-color: #e34f4f;
  border-radius: 0 5px 5px 0;
  color: #fff;
}

.banner .wrapper .search button:hover {
  background-color: #ff3636;
  cursor: pointer;
}

.banner .wrapper .bear {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 4;
  transform: translate(-50%, 0);
  width: 100px;
  height: 50px;
  background: url(@/assets/img/bear.png) repeat-x;
  background-size: auto 100%;

  animation: running_bear 0.8s steps(8) infinite, move_bear 5s forwards;
}

@keyframes running_bear {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 1600px 0;
  }
}

@keyframes move_bear {
  0% {
    left: 0;
  }
  100% {
    left: 50%;
    transform: translateX(-50%);
  }
}

.wrapper {
  width: 1240px;
  margin: 0 auto;
}

.main {
  background-color: #f2f5f8;
  overflow: hidden;
}

.main .wrapper .part {
  background-color: #fff;
  /* margin-top: 10px; */
  overflow: hidden;
}

.main .wrapper .part .header {
  padding: 20px;
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  box-sizing: border-box;
}

.main .wrapper .part .header a > * {
  font-size: 14px;
  vertical-align: middle;
}

.main .wrapper .items {
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
}

.main .wrapper .items .block {
  float: left;
  width: 240px;
  padding: 25px 20px;
  margin-right: 26px;
}

.main .wrapper .items .block:nth-child(4n) {
  margin-right: 0;
}

.main .wrapper .items .block:hover {
  box-shadow: 0 0 10px 4px #aaa;
}

.main .items .block .top img {
  float: left;
  width: 32px;
}

.main .items .block .top h4 {
  float: left;
  margin-left: 10px;
  font-weight: normal;
  line-height: 32px;
}

.main .items .block .top i {
  float: right;
  margin-top: 8px;
  border: 1px solid #aaa;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 8px;
  color: #aaa;
  text-align: center;
}

.main .items .block .top i:hover {
  background-color: rgb(243, 236, 236);
}

.main .items .block p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 5px 0 10px 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  /* text-align: justify; */
  color: rgb(142, 137, 137);
}

.main .cite .items .block {
  padding: 0;
  border-radius: 10px;
  width: 280px;
  overflow: hidden;
  box-shadow: 0 0 5px 2px #aaa;
}

.main .cite .items .block img {
  width: 280px;
  height: 180px;
}

.main .cite .items .block h4 {
  margin: 10px 0 10px 20px;
}

.main .cite .items .block p {
  margin: 0 20px 20px;
  line-height: 20px;
}

.wrapper p {
  font-size: 16px;
  margin-bottom: 20px;
}
</style>
